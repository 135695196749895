/* eslint-disable react-hooks/exhaustive-deps */
import { createClient } from '@supabase/supabase-js'
import React from 'react'
import { useState } from 'react'
//import PropTypes from 'prop-types'
import { FullScreen, useFullScreenHandle } from 'react-full-screen'
import { useNavigate, useParams } from 'react-router-dom'
import routesPaths from './Routes-paths'
import { useEffect } from 'react'
import { CProgress } from '@coreui/react-pro'
import { indexedDB } from './indexedDB'
import useInterval from './customHooks/useInterval'
import _ from 'lodash'

async function readData(url, key, savedVideo) {
  try {
    let newBlob
    if (!savedVideo) {
      const res = await fetch(url, {
        method: 'GET',
      })

      const blob = await res.blob()
      newBlob = new Blob([blob], {
        type: 'video/mp4',
      })
      const base64Blob = await blobToBase64(newBlob)
      debugger
      indexedDB.videos.add({
        key,
        base64: base64Blob,
        updatedAt: new Date().toDateString(),
      })
    } else {
      newBlob = await fetch(savedVideo.base64).then((res) => res.blob())
    }
    /*
     */
    return window.URL.createObjectURL(newBlob)
  } catch (error) {
    return
  }
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}
export const fullScreenHighOrder = (WrappedComponent) => {
  function SubComponent(props) {
    const handle = useFullScreenHandle()
    //const [fullscreen, setFullscreen] = useState(false)
    const [screenDta, setScreenDta] = useState({})
    const [downloading, setDownloading] = useState({
      total: 1,
      actual: 0,
    })
    const params = useParams()
    const navigate = useNavigate()

    const requestFiles = async () => {
      try {
        //const newVideos = await readPublicFiles()
        const sbClient = createClient(
          process.env.REACT_APP_SUPABASE_URL,
          process.env.REACT_APP_SUPABASE_KEY,
        )

        const { data, error } = await sbClient
          .from('screen')
          .select(
            `
            id, 
            title,
            rules,
            instagram,
            facebook,
            twitter,
            localPhone,
            mobilePhone,
            direction,
            s3_logo_key,
            videos (s3_key, fullscreen,id),
            slides ( s3_img_key, title, description )
          `,
          )
          .eq('id', params.id)
            
        if (error || data.length === 0) navigate(routesPaths._500)
        //revision de nuevo datos
        const oldDta = { ...screenDta }
        delete oldDta.videosURL
        delete oldDta.videoByHours
        if (_.isEqual(data[0], oldDta)) return
        //en caso de ser distintos se procede normalmente
        const savedVideosFrom = localStorage.getItem('idScreen') || ''
        
        if (savedVideosFrom !== `${data[0].id}`) {
          await indexedDB.videos.clear()
        }
        localStorage.setItem('idScreen', `${data[0].id}`)
        const localVideos = await indexedDB.videos.toArray()

        const videos = data[0].videos.map((it) => ({
          src: process.env.REACT_APP_AWS_STORAGE_URL + it.s3_key,
          fullscreen: it.fullscreen,
          key: it.s3_key,
          id: it.id,
        }))
        setDownloading((prev) => {
          return { ...prev, total: videos.length }
        })
        const urls = [],
          urlDic = {}
        for (const video of videos) {
          const index = localVideos.findIndex((it) => {
            return it.key === video.key
          })
          const [removed] = index >= 0 ? localVideos.splice(index, 1) : [undefined]

          const urlBlob = await readData(video.src, video.key, removed)
          urls.push({ src: urlBlob, fullscreen: video.fullscreen })
          urlDic[video.id] = { src: urlBlob, fullscreen: video.fullscreen }
          setDownloading((prev) => {
            return { ...prev, actual: prev.actual + 1 }
          })
        }
        //deleting

        for (const toDelete of localVideos) {
          await indexedDB.videos.delete(toDelete.key)
        }
        debugger
        const allVideos = videos.reduce((acc, curr) => {
          acc[curr.id] = []
          return acc
        }, {})
        let rules = data[0]?.rules || {}

        rules = { ...allVideos, ...rules }
        const videoKeys = Object.keys(rules)
        const arraySets = [...Array(24)].map((x) => new Set())
        for (let i = 0; i < videoKeys.length; i++) {
          const timesForTheVideo = rules[videoKeys[i]] || []

          if (timesForTheVideo.length === 0) {
            arraySets.forEach((sets) => {
              sets.add(videoKeys[i])
            })
          } else {
            timesForTheVideo.forEach(({ start, end }) => {
              for (let j = start; j < end; j++) {
                arraySets[j].add(videoKeys[i])
              }
            })
          }
        }

        const videosByHours = arraySets.map((sets) => Array.from(sets).map((key) => urlDic[key]))
        setTimeout(() => {
          setScreenDta({ ...data[0], videosURL: urls, videoByHours: videosByHours })
        }, 500)

        //if (error) navigate(routesPaths._500)
      } catch (error) {
        console.log(error)
      }
    }
    useEffect(() => {
      requestFiles()
      //window.addEventListener('keydown', handle.enter)
      return () => {
        //window.removeEventListener('keydown', handle.enter)
      }
    }, [])
    
    useInterval(() => {
      requestFiles()
    }, 60000 *30)

    return (
      <div>
        <FullScreen
          /*
          onChange={(itsFullScreen) => {
            setFullscreen(itsFullScreen)
          }}
          */
          handle={handle}
        >
          {screenDta.id ? (
            <WrappedComponent screen={screenDta} {...props} />
          ) : (
            <>
              <p>
                Descargando Videos ({downloading.actual} / {downloading.total})
              </p>
              <CProgress
                color="danger"
                variant="striped"
                animated
                value={(downloading.actual / downloading.total) * 100}
              />
            </>
          )}
        </FullScreen>
      </div>
    )
  }
  SubComponent.propTypes = {}

  return SubComponent
}
