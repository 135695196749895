import React from 'react'
import CIcon from '@coreui/icons-react'
import {  cilUser, cilScreenDesktop, cilVideo, cilImage } from '@coreui/icons'
import { /*CNavGroup,*/ CNavItem, CNavTitle } from '@coreui/react-pro'
import routesPaths from './utils/Routes-paths'

const _nav = [
  //titulos
  {
    component: CNavTitle,
    name: 'Menu',
  },
  //links
  {
    component: CNavItem,
    name: 'Pantallas',
    to: routesPaths.dashboard.screens,
    icon: <CIcon icon={cilScreenDesktop} customClassName="nav-icon" />,
    // badge: {
    //   color: 'info-gradient',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Videos',
    to: routesPaths.dashboard.videos,
    icon: <CIcon icon={cilVideo} customClassName="nav-icon" />,
    // badge: {
    //   color: 'primary-gradient',
    //   text: 'NEW',
    // },
  },
  {
    component: CNavItem,
    name: 'Slides',
    to: routesPaths.dashboard.slides,
    icon: <CIcon icon={cilImage} customClassName="nav-icon" />,
    // badge: {
    //   color: 'secondary-gradient',
    //   text: 'NEW',
    // },
  },
  {
    roles:['admin'],
    component: CNavItem,
    name: 'Usuarios',
    to: routesPaths.admin.users,
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
    badge: {
      color: 'secondary-gradient',
      text: 'ADMIN',
    },
  },
  //listas
  /*
  {
    component: CNavGroup,
    name: 'Base',
    to: '/base',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
        name: 'Accordion',
        to: '/base/accordion',
      },
      {
        component: CNavItem,
        name: 'Breadcrumb',
        to: '/base/breadcrumbs',
      },
      {
        component: CNavItem,
        name: 'Cards',
        to: '/base/cards',
      },
    ],
  },
  */
]

export default _nav
