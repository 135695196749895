/* eslint-disable react-hooks/exhaustive-deps */
import { cilLockLocked } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import { createClient } from '@supabase/supabase-js'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
//import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
//import { getDataUser } from 'src/Redux/reducers/user.reducer'
import UserTable from 'src/components/tables/user.table'
import routesPaths from 'src/utils/Routes-paths'
import { sbClient } from 'src/utils/config/supabase-configuration'
import useArray from 'src/utils/customHooks/useArray'
import useToast from 'src/utils/customHooks/useToast'

const UsersAdminView = () => {
  const navigate = useNavigate()
  const addToast = useToast()
  //const { user } = useSelector(getDataUser)
  const {
    array: users_array,
    set: users_set,
    //push: users_push,
    //filter :  users_filter,
    update :  users_update,
    //remove :  users_remove, 
    //clear :  users_clear 
} = useArray([])

  const requestFiles = async () => {
    try {
      //const newVideos = await readPublicFiles()

      const { data, error } = await sbClient.from('user').select()
    
      if (error) navigate(routesPaths._500)
      users_set(data)
    } catch (error) {}
  }
  useEffect(() => {
    requestFiles()
    return () => {}
  }, [])
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    repassword: '',
  })
  const [errorformData, setErrorFormData] = useState({
    email: false,
    password: false,
    repassword: false,
  })
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (errorformData.email || errorformData.password || errorformData.repassword) return
      //set register
      const sbClientAdmin = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_SERVICE_ROLE,
      )
      /*const { data: response } = */ await sbClientAdmin.auth.admin.createUser({
        email: formData.email,
        email_confirm: true,
        password: formData.password,
        options: {
          redirectTo: process.env.REACT_APP_CURRENT_URL + routesPaths.dashboard.base,
          data: {},
        },
      })
      requestFiles()
      addToast({
        title:'Usuario creado'
      })
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorFormData({ ...errorformData, email: true })
      }
      console.log(error)
    }
  }
  const handleValidatePassword = async (data) => {
    const errors = { ...errorformData }
    if (data.password.length < 6) {
      errors.password = true
    } else if (data.password !== data.repassword) {
      errors.password = true
      errors.repassword = true
    } else {
      errors.password = false
      errors.repassword = false
    }
    setErrorFormData(errors)
  }
  const handleChange = ({ target: { name, value } }) => {
    const data = { ...formData }
    data[name] = value
    setFormData(data)
    if (name === 'password' || name === 'repassword') {
      console.log(data)
      handleValidatePassword(data)
    } else {
      setErrorFormData({ ...errorformData, email: false })
    }
  }
  const handleToggleAdmin = async (userEdit) =>{
    console.log(userEdit)
    const sbClientAdmin = createClient(
      process.env.REACT_APP_SUPABASE_URL,
      process.env.REACT_APP_SUPABASE_SERVICE_ROLE,
    )
    const newRoles = ['user']
    if(!userEdit.role.includes('admin')) {
      newRoles.push('admin')
    }
    const {data, error} = await sbClientAdmin.from('user')
    .update({ roles: newRoles })
    .eq('id', userEdit.id)
    .select()
    console.log(data)
    if(error || data.length ===0 ) return
    users_update(userEdit.index,data[0] )
  }
  return (
    <>
      <h1 className="mb-2">User</h1>
      <div className="mb-3">
        <CCard style={{ border: '2px solid #2829339f' }} className="flex-grow-1 ">
          <CCardHeader>
            <CCardTitle>Nuevo Usuario</CCardTitle>
          </CCardHeader>

          <CCardBody className="">
            <CForm onSubmit={handleSubmit}>
              <h5>Register</h5>
              <CRow>
                <CCol xs='12'>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      onChange={handleChange}
                      value={formData.email}
                      invalid={errorformData.email}
                      name="email"
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                    />
                  </CInputGroup>
                </CCol>
                <CCol md="6">
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      onChange={handleChange}
                      value={formData.password}
                      invalid={errorformData.password}
                      name="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="new-password"
                    />
                  </CInputGroup>
                </CCol>
                <CCol md="6">
                  <CInputGroup className="mb-4">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      onChange={handleChange}
                      value={formData.repassword}
                      invalid={errorformData.repassword}
                      name="repassword"
                      type="password"
                      placeholder="Repeat password"
                      autoComplete="new-password"
                    />
                  </CInputGroup>
                </CCol>
              </CRow>
              <div className="d-grid">
                <CButton type="submit" color="success">
                  Crear
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </div>
      <CCard>
        <CCardBody>
          <UserTable users={users_array} onToggleAdmin={handleToggleAdmin} />
        </CCardBody>
      </CCard>
    </>
  )
}

export default UsersAdminView
