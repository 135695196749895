import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import { createClient } from '@supabase/supabase-js'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDataUser } from 'src/Redux/reducers/user.reducer'
import DropzoneInput from 'src/components/DropzoneInput'
import VideosTable from 'src/components/tables/videos.table'
import routesPaths from 'src/utils/Routes-paths'
import { deleteFileInStorage, setFileInStorage } from 'src/utils/S3_Storage'
//import { sbClient } from 'src/utils/config/supabase-configuration'
import useToast from 'src/utils/customHooks/useToast'
import { v4 as uuidv4 } from 'uuid'

function VideosView() {
  const navigate = useNavigate()
  const [videos, setVideos] = useState([])
  const [totalVideos, setTotalVideos] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const addToast = useToast()
  const { user } = useSelector(getDataUser)
  const [loading, setLoading] = useState(false)

  const [newVideoDta, setNewVideoDta] = useState({
    title: '',
    fullscreen: '',
    file: undefined,
  })

  const requestFiles = async (
    activePage = 1,
    columnFilter = {},
    columnSorter = {},
    itemsPerPage = 10,
  ) => {
    try {
      //const newVideos = await readPublicFiles()

      const pageSQL = activePage - 1
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const orderObj = columnSorter.column
        ? {
            column: columnSorter?.column,
            asc: columnSorter.state === 'asc',
          }
        : {
            column: 'title',
            asc: true,
          }
      let data, count
      if (columnFilter.title) {
        const { data: tempDta, error } = await sbClient
          .from('videos')
          .select()
          .eq('uploaded_by', user.id)
          .like('title', `%${columnFilter.title}%`)
          .order(orderObj.column, { ascending: orderObj.asc })
          .range(pageSQL * itemsPerPage, (pageSQL + 1) * itemsPerPage)

        const { count: tempCount } = await sbClient
          .from('videos')
          .select('*', { count: 'exact', head: true })
          .eq('uploaded_by', user.id)
          .like('title', `%${columnFilter.title}%`)
        if (error) navigate(routesPaths._500)
        data = tempDta
        count = tempCount
      } else {
        const { data: tempDta, error } = await sbClient
          .from('videos')
          .select()
          .eq('uploaded_by', user.id)
          .order(orderObj.column, { ascending: orderObj.asc })
          .range(pageSQL * itemsPerPage, (pageSQL + 1) * itemsPerPage)

        const { count: tempCount } = await sbClient
          .from('videos')
          .select('*', { count: 'exact', head: true })
          .eq('uploaded_by', user.id)
        if (error) navigate(routesPaths._500)
        data = tempDta
        count = tempCount
      }
      setVideos(data)
      setTotalVideos(count)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDelete = async (vdo) => {
    
    try {
      const key = vdo.s3_key
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const { error } = await sbClient.from('videos').delete().eq('id', vdo.id)
      if (error) return
      await deleteFileInStorage(key)
    } catch (error) {}
  }
  const handleEditVdo = async (vdo) => {
    
    try {
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const { error } = await sbClient.from('videos')
      .update({ fullscreen: vdo.fullscreen, title:vdo.title })
      .eq('id', vdo.id)
      if (error) return
    } catch (error) {}
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setNewVideoDta((prev) => {
      return { ...prev, [name]: value }
    })
  }

  const handleDropFile = (e = []) => {
    if (e.length === 0) return
    setNewVideoDta((prev) => {
      const next = { ...prev, file: e[0] }
      if (!next.title) next.title = e[0].name
      return next
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      
      if (!newVideoDta.file || !newVideoDta.title) throw new Error('Faltan datos')
      const key =
        `public/${user.id}/videos/` +
        `${newVideoDta.file.name}_`.replace('.mp4', '').substring(0, 15) +
        uuidv4().replace(/-/g, '_') +
        '.mp4'
      
      await setFileInStorage({
        file: newVideoDta.file,
        key: key,
      })

      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const { error } = await sbClient.from('videos').insert({
        title: newVideoDta.title,
        s3_key: key,
        uploaded_by: user.id,
        fullscreen: Boolean(newVideoDta.fullscreen),
      })

      if (error) throw error
      addToast({
        title: 'Video Subido exitosamente',
        body: '',
      })
      setNewVideoDta({
        title: '',
        fullscreen: false,
        file: undefined,
      })
      requestFiles(1, {}, {}, itemsPerPage)
    } catch (error) {
      let msg = typeof error === typeof '' ? error : error.message
      addToast({
        title: 'Parece que hubo un error',
        body: msg || 'Estamos trabajando en ello',
      })
    }
    setLoading(false)
  }
  return (
    <>
      <h1 className="mb-2">Videos</h1>
      <div className="mb-3">
        <CCard style={{ border: '2px solid #2829339f' }} className="flex-grow-1 position-relative ">
          <CCardHeader>
            <CCardTitle>Ingrese nuevo video</CCardTitle>
          </CCardHeader>

          <CCardBody className="">
            <CForm onSubmit={handleSubmit}>
              <CRow className="justify-content-center ">
                <CCol md="8">
                  <CRow>
                    <CCol>
                      <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1"></CInputGroupText>
                        <CFormInput
                          value={newVideoDta.title}
                          name="title"
                          onChange={handleChange}
                          placeholder="Titulo "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol xs="auto">
                      <CFormCheck
                        checked={newVideoDta.fullscreen}
                        name="fullscreen"
                        onChange={(e) => {
                          const { name, checked } = e.target
                          setNewVideoDta((prev) => {
                            return { ...prev, [name]: checked }
                          })
                        }}
                        button={{ color: 'primary', variant: 'outline' }}
                        id="btn-check-outlined"
                        autoComplete="off"
                        label={newVideoDta.fullscreen ? 'Pantalla completa' : 'Normal'}
                      />
                    </CCol>
                  </CRow>

                  <div
                    className="bg-white opacity-75 mb-2"
                    style={{ color: 'black', height: '150px' }}
                  >
                    <DropzoneInput
                      loaded={
                        newVideoDta.file && (
                          <video
                            height={'100px'}
                            src={URL.createObjectURL(newVideoDta.file)}
                            alt=""
                          />
                        )
                      }
                      accept={{
                        'video/mp4': ['.mp4'],
                      }}
                      onDrop={handleDropFile}
                    >
                      {newVideoDta.file ? (
                        <>{newVideoDta.file.name}</>
                      ) : (
                        <p>arrastra aquí el video</p>
                      )}
                    </DropzoneInput>
                  </div>
                </CCol>
              </CRow>
              <CButtonGroup className="d-flex justify-content-end ">
                <CButton disabled={loading} type={'submit'} color="primary">
                  Crear
                </CButton>
              </CButtonGroup>
            </CForm>
          </CCardBody>

          {loading && (
            <div
              className="position-absolute bg-black  opacity-75 d-flex  justify-content-center align-items-center"
              style={{ inset: 0 }}
            >
              <CSpinner color="primary" />
            </div>
          )}
        </CCard>
      </div>
      <CCard>
        <CCardBody>
          <VideosTable
            items={videos}
            count={totalVideos}
            onRequestItems={requestFiles}
            onDeleteItem={handleDelete}
            onEditItem={handleEditVdo}
            itemsPerPageHook={[itemsPerPage, setItemsPerPage]}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default VideosView
