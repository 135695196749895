/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

const useInterval = (callback,delay,runInit=false) => {
    const savedCallback = React.useRef();
  
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    React.useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      let id = setInterval(tick, delay);
      if(runInit) tick()
      return () => clearInterval(id);
    }, []);
  };

  export default useInterval