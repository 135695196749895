import routesPaths from './utils/Routes-paths'

// examples

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: routesPaths.dashboard.screens, name: 'Pantallas',  },
  { path: routesPaths.dashboard.videos, name: 'Videos',  },
  { path: routesPaths.dashboard.slides, name: 'Slide',  },
  { path: routesPaths.dashboard.newScreen, name: 'Nueva pantalla',  },
]

export default routes
