import React from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'
import CIcon from '@coreui/icons-react'
import { cilDataTransferDown } from '@coreui/icons'

function DropzoneInput({
  loaded = false,
  className = '',
  accept,
  children = (
    <>
      <p>Importar documento o arrastrar aquí</p>
    </>
  ),
  dragMsg = 'Arrastra aquí el documento',
  onDrop = () => {},
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept })

  return (
    <div className={`dropzone_input_cont h-100  ${className}`} {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="d-flex flex-column justify-content-center align-items-center h-100 ">
        <span className={`dropzone-icon ${loaded ? 'dropzone-icon-loaded' : ''}`}>
          {!loaded ? (
            <div style={{ height: '100px' }} className="p-3">
              <CIcon
                height={'100%'}
                color="black"
                icon={cilDataTransferDown}
                customClassName="nav-icon"
              />
            </div>
          ) : (
            <div className="p-3">
            {loaded}
            </div>
          )}
        </span>

        {isDragActive ? <p>{dragMsg}</p> : children}
      </div>
    </div>
  )
}

DropzoneInput.propTypes = {
  loaded: PropTypes.any,
  className: PropTypes.string,
  accept: PropTypes.object,
  children: PropTypes.any,
  dragMsg: PropTypes.string,
  onDrop: PropTypes.func,
}

export default DropzoneInput
