
const routesPaths = {
  login: '/login',
  register: '/register',
  _404: '/404',
  _500: '/500',
  dashboard: {
    base: '/dashboard',
    screens: '/dashboard/screens',
    videos: '/dashboard/videos',
    slides: '/dashboard/slides',
    newScreen: '/dashboard/new-screen',
    editScreen: (id = ':id') =>(`/dashboard/edit-screen/${id}`),
  },
  admin: {
    base: '/admin/dashboard',
    users: '/admin/dashboard/users',
  },
  view: {
    base:'',
    basic : (id = ':id') =>(`/b/${id}`),
    fullscreen : (id = ':id') =>(`/f/${id}`),
  }
}
export default routesPaths
