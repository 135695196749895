import { CButton, CButtonGroup,  CSmartTable } from '@coreui/react-pro'
import React from 'react'
import PropTypes from 'prop-types'

function UserTable({ users = [], onToggleAdmin = () => {}, onDelete = () => {} }) {
  //const [details, setDetails] = useState([])
  const columns = [
    {
      key: 'email',
      _style: { width: '20%' },
    },
    'registered',
    {
      key: 'role',
      _style: { width: '20%' },
    },
    {
      key: 'show_details',
      label: '',
      _style: { width: '1%' },
      filter: false,
      sorter: false,
    },
  ]
  /*
  const toggleDetails = (index) => {
    const position = details.indexOf(index)
    let newDetails = details.slice()
    if (position !== -1) {
      newDetails.splice(position, 1)
    } else {
      newDetails = [...details, index]
    }
    setDetails(newDetails)
  }
  */
  return (
    <CSmartTable
      cleaner
      clickableRows
      columns={columns}
      //columnFilter
      columnSorter
      //footer
      items={users.map((user,i) => {
        return {
          id: user.id,
          email: user.email,
          registered: user.created_at,
          role: user.roles.sort().join(', '),
          index: i,
        }
      })}
      itemsPerPageSelect
      itemsPerPage={10}
      pagination
      /*
      onFilteredItemsChange={(items) => {
        console.log(items)
      }}
      onSelectedItemsChange={(items) => {
        console.log(items)
      }}
      */
      scopedColumns={{
        show_details: (item) => {
          return (
            <td className="py-2">
                <CButtonGroup className='w-100'>
                  <CButton  onClick={()=>{onToggleAdmin(item)}} size="sm" color="secondary">
                    {item.role.includes('admin')? 'Quitar admin': 'Añadir privilegios'}
                  </CButton>
                  {/*
                  <CButton size="sm" color="primary" className="ml-1 text-white ">
                    Delete
                  </CButton>
                  */}
                </CButtonGroup>
            </td>
          )
        },
        /*
              <CButton
                color="primary"
                variant="outline"
                shape="square"
                size="sm"
                onClick={() => {
                  toggleDetails(item.id)
                }}
              >
                {details.includes(item.id) ? 'Menos' : 'Más'}
              </CButton>
        details: (item) => {
          return (
            <CCollapse visible={details.includes(item.id)}>
              <CCardBody className="p-3">
                <CButtonGroup className='w-100'>
                  <CButton  size="sm" color="secondary">
                    User Settings
                  </CButton>
                  <CButton size="sm" color="primary" className="ml-1">
                    Delete
                  </CButton>
                </CButtonGroup>
              </CCardBody>
            </CCollapse>
          )
        },
        */
      }}
      selectable
      sorterValue={{ column: 'status', state: 'asc' }}
      tableFilter
      tableProps={{
        className: 'add-this-class',
        responsive: true,
        striped: true,
        hover: true,
      }}
      tableBodyProps={{
        className: 'align-middle',
      }}
    />
  )
}

UserTable.propTypes = {
  users: PropTypes.array,
  onToggleAdmin: PropTypes.func,
  onDelete: PropTypes.func,
}
export default UserTable
