import reducers from './reducers'
import { configureStore } from '@reduxjs/toolkit'
const initialState = {
  pageStatus: {
    sidebarShow: true,
    asideShow: false,
    sidebarUnfoldable: false,
    theme: 'default',
  },
  user: {
    token: '',
    notifications: [],
    data: undefined,
  },
}

export const store = configureStore({
  middleware: [],
  reducer: reducers,
  preloadedState: initialState,
  devTools: Boolean(process.env.REACT_APP_DEV_MODE),
})
