
import { SET_DATA_USER, SET_NOTIFICATIONS, SET_SIGN_OUT, SET_TOKEN_USER } from "../const";

export const setTokenUser = (value) => ({ type: SET_TOKEN_USER, value });
export const setDataUser = (value) => ({ type: SET_DATA_USER, value });
export const setNotifications = (value) => ({ type: SET_NOTIFICATIONS, value });
export const setSignOut = (value) => ({ type: SET_SIGN_OUT });

/**
 *
 * @param {{name:String, lastName:String, email:String, password:String, repassword:String, level:String}} payload
 * @returns
 */
export const RequestLote = (payload = {}) => {
    return async (dispatch, getState) => {
      try {
        dispatch(
            setSignOut()
        );
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    };
  };