
export const SET_SIDEBAR_SHOW = 'SET_SIDEBAR_SHOW'
export const SET_ASIDE_SHOW = 'SET_ASIDE_SHOW'
export const SET_SIDEBAR_UNFOLDABLE = 'SET_SIDEBAR_UNFOLDABLE'
export const SET_THEME = 'SET_THEME'
//users const
export const SET_TOKEN_USER = 'SET_TOKEN_USER'
export const SET_DATA_USER = 'SET_DATA_USER'
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_SIGN_OUT = 'SET_SIGN_OUT'
