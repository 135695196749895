/* eslint-disable react-hooks/exhaustive-deps */

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CCollapse,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CImage,
  CInputGroup,
  CInputGroupText,
  CMultiSelect,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import DropzoneInput from 'src/components/DropzoneInput'
//import { sbClient } from 'src/utils/config/supabase-configuration'
import PropTypes from 'prop-types'
import useToast from 'src/utils/customHooks/useToast'
import { useSelector } from 'react-redux'
import { getDataUser } from 'src/Redux/reducers/user.reducer'
import { deleteFileInStorage, setFileInStorage } from 'src/utils/S3_Storage'
import { useNavigate, useParams } from 'react-router-dom'
import routesPaths from 'src/utils/Routes-paths'
import { createClient } from '@supabase/supabase-js'
import { v4 as uuidv4 } from 'uuid'
import { cilTrash } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

const times = [
  "0:00",
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00"
]
function CreateScreensView() {
  const navigate = useNavigate()
  const addToast = useToast()
  const { user } = useSelector(getDataUser)
  const [startInput, setStartInput] = useState(0)
  const [endInput, setEndInput] = useState(23)
  const [activeRule, setActiveRule] = useState('')
  const params = useParams()
  const [options, setOptions] = useState({
    video_options: [],
    slides_options: [],
  })
  const [loading, setLoading] = useState(false)
  const [formDta, setFormDta] = useState({
    videos: [],
    rules: {},
    slides: [],
    logo: null,
    title: '',
    instagram: '',
    facebook: '',
    twitter: '',
    localPhone: '',
    mobilePhone: '',
    direction: '',
  })
  const requestFiles = async () => {
    try {
      //const newVideos = await readPublicFiles()
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )

      const { data: slides } = await sbClient.from('slides').select()
      const { data: videos } = await sbClient.from('videos').select()

      let selected = []
      if (params.id) {
        const { data: screenArray, error: screenError } = await sbClient
          .from('screen')
          .select(
            `
            id, 
            title,
            rules,
            instagram,
            facebook,
            twitter,
            localPhone,
            mobilePhone,
            direction,
            s3_logo_key,
            videos (id,s3_key,title),
            slides (id, s3_img_key, title, description )
            `,
          )
          .eq('id', params.id)

        if (screenError || screenArray.length === 0) navigate(routesPaths.dashboard.screens)
        
        const newData = {
          ...screenArray[0],
          videos: screenArray[0].videos.map((vd) => {
            selected.push(vd.id)
            return { value: vd.id, text: vd.title }
          }),
          slides: screenArray[0].slides.map((slide) => {
            selected.push(slide.id)
            return {
              value: slide.id,
              text: slide.title,
              description: slide.description,
              img: `${process.env.REACT_APP_AWS_STORAGE_CLOUDFRONT_URL}${slide.s3_img_key}`,
            }
          }),
          rules: screenArray[0]?.rules || {},
          logo: null,
        }
        setFormDta(newData)
      }
      const save = {
        video_options: videos.map((vd) => ({
          value: vd.id,
          text: vd.title,
          selected: Boolean(selected.find((it) => it === vd.id)),
        })),
        slides_options: slides.map((slide) => ({
          value: slide.id,
          text: slide.title,
          description: slide.description,
          img: `${process.env.REACT_APP_AWS_STORAGE_CLOUDFRONT_URL}${slide.s3_img_key}`,
          selected: Boolean(selected.find((it) => it === slide.id)),
        })),
      }
      setOptions(save)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    requestFiles()
    return () => {}
  }, [])

  const handleDropFile = (e = []) => {
    if (e.length === 0) return
    setFormDta((prev) => {
      return { ...prev, logo: e[0] }
    })
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormDta((prev) => {
      const newDta = { ...prev }
      newDta[name] = value
      if (name === 'videos') {
        const newRules = {}
        value.forEach((it) => {
          newRules[it.value] = newDta.rules?.[it.value] || undefined
        })
        newDta.rules = newRules
      }
      return newDta
    })
  }

  const handleSubmit = async (e) => {
    setLoading(true)
    try {
      if (!formDta.title) throw new Error('Faltan titulo')
      let key =
        `public/${user.id}/images/` +
        `${formDta?.logo?.name}_`.replace('.mp4', '').substring(0, 15) +
        uuidv4().replace(/-/g, '_') +
        '.mp4'
      if (formDta.s3_logo_key && formDta.logo) {
        try {
          await deleteFileInStorage(formDta.s3_logo_key)
        } catch (error) {}
      } else if (formDta.s3_logo_key) {
        key = formDta.s3_logo_key
      } else if (!formDta.logo) {
        //return
        key=''
      }
      if (formDta.logo)
        await setFileInStorage({
          file: formDta.logo,
          key: key,
        })
      let screen

      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const rules = formDta.rules
      formDta.videos.forEach(vi=>{
        if(!rules[vi.value]) rules[vi.value] = []
      })
      if (!params.id) {
        const { error, data } = await sbClient
          .from('screen')
          .insert({
            title: formDta.title,
            rules: (formDta.rules),
            s3_logo_key: key,
            instagram: formDta.instagram,
            facebook: formDta.facebook,
            twitter: formDta.twitter,
            localPhone: formDta.localPhone,
            mobilePhone: formDta.mobilePhone,
            direction: formDta.direction,
            uploaded_by: user.id,
          })
          .select()

        if (error) throw error
        screen = data[0]
      } else {
        const { error, data } = await sbClient
          .from('screen')
          .update({
            title: formDta.title,
            rules: (formDta.rules),
            s3_logo_key: key,
            instagram: formDta.instagram,
            facebook: formDta.facebook,
            twitter: formDta.twitter,
            localPhone: formDta.localPhone,
            mobilePhone: formDta.mobilePhone,
            direction: formDta.direction,
            uploaded_by: user.id,
          })
          .eq('id', params.id)
          .select()

        if (error) throw error
        screen = data[0]
        const { error: deleteError } = await sbClient
          .from('screen_videos_reference')
          .delete()
          .eq('screen_id', params.id)
        const { error: deleteErrorSlides } = await sbClient
          .from('screen_slides_reference')
          .delete()
          .eq('screen_id', params.id)
        if (deleteError || deleteErrorSlides) {
          addToast({
            title: 'Hubo un error',
          })
        }
      }

      const screen_slides_reference = formDta.slides.map((sli) => ({
          slide_id: sli.value,
          screen_id: screen.id,
        })),
        screen_videos_reference = formDta.videos.map((sli) => ({
          video_id: sli.value,
          screen_id: screen.id,
        }))
      const { error: relSlideError } = await sbClient
        .from('screen_slides_reference')
        .insert(screen_slides_reference)
      const { error: relVideoError } = await sbClient
        .from('screen_videos_reference')
        .insert(screen_videos_reference)
      if (relVideoError || relSlideError) {
        throw relVideoError || relSlideError
      }
      addToast({
        title: 'Pantalla creada exitosamente',
        body: '',
      })
      navigate(routesPaths.dashboard.screens)
    } catch (error) {
      let msg = typeof error === typeof '' ? error : error.message
      addToast({
        title: 'Parece que hubo un error',
        body: msg || 'Estamos trabajando en ello',
      })
    }
    setLoading(false)
  }
  const handleAddNewRule = (key) => {
    let start, end

    if (startInput < endInput) {
      start = startInput
      end = endInput
    } else {
      start = endInput
      end = startInput
    }
    setFormDta((prev) => {
      const newDta = { ...prev }
      const actualVideoRules = newDta.rules?.[key] || []
      actualVideoRules.push({
        start,
        end,
      })
      newDta.rules[key] = actualVideoRules
      return newDta
    })
  }
  const handleAddRemoveRule = (key,indexRule) => {
    setFormDta((prev) => {
      const newDta = { ...prev }
      const actualVideoRules = newDta.rules?.[key] || []
      if(indexRule>=0) actualVideoRules.splice(indexRule,1)
      newDta.rules[key] = actualVideoRules
      return newDta
    })
  }

  return (
    <div>
      <h1>{params.id ? 'Editar pantalla' : 'Nueva pantalla'}</h1>
      <CCard className="position-relative ">
        <CCardBody>
          <CForm
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <CRow>
              <CCol xs="12">
                <CInputGroup className="mb-3">
                  <CInputGroupText></CInputGroupText>
                  <CFormInput
                    className="opacity-75"
                    onChange={handleChange}
                    value={formDta.title}
                    name="title"
                    type="text"
                    placeholder="Titulo"
                    aria-describedby="exampleFormControlInputHelpInline"
                  />
                </CInputGroup>
              </CCol>
              <CCol xs={'12'} md={'6'} className="mb-2">
                {options.video_options.length > 0 && (
                  <CMultiSelect
                    virtualScroller
                    options={options.video_options}
                    onChange={(options) => {
                      handleChange({
                        target: {
                          name: 'videos',
                          value: options,
                        },
                      })
                    }}
                    label="videos"
                    //text="Please select your framework."
                  />
                )}
              </CCol>
              <CCol xs={'12'} md={'6'} className="mb-2">
                {options.slides_options.length > 0 && (
                  <CMultiSelect
                    virtualScroller
                    options={options.slides_options}
                    onChange={(options) => {
                      handleChange({
                        target: {
                          name: 'slides',
                          value: options,
                        },
                      })
                    }}
                    label="Slides"
                    //text="Please select your framework."
                    optionsStyle="text"
                    optionsTemplate={(option) => (
                      <CRow>
                        <CCol xs="3">
                          <CImage width={'100%'} src={option.img}></CImage>
                        </CCol>
                        <CCol>
                          <p className="fw-bold mb-0">{option.text}</p>
                          <small className="option-description">{option.description}</small>
                        </CCol>
                      </CRow>
                    )}
                  ></CMultiSelect>
                )}
              </CCol>
              <CCol xs={'12'} md={'6'} className="mb-2 d-flex flex-column ">
                <CFormLabel className="col-sm-2 col-form-label w-100">Ingrese Logotipo (opcional)</CFormLabel>
                <CCard style={{ border: '2px solid #2829339f' }} className="flex-grow-1 ">
                  <CCardBody className="bg-white opacity-75" style={{ color: 'black' }}>
                    <DropzoneInput
                      loaded={
                        formDta.logo && (
                          <img height={'200px'} src={URL.createObjectURL(formDta.logo)} alt="" />
                        )
                      }
                      accept={{
                        'image/*': ['.jpeg', '.png'],
                      }}
                      onDrop={handleDropFile}
                    >
                      {formDta.logo ? (
                        <>{formDta.logo.name}</>
                      ) : (
                        <p>
                          {formDta.s3_logo_key
                            ? formDta.s3_logo_key?.split('/')[
                                formDta.s3_logo_key?.split('/').length - 1
                              ]
                            : 'arrastra aquí el Logo'}
                        </p>
                      )}
                    </DropzoneInput>
                  </CCardBody>
                </CCard>
                <span>700x400</span>
              </CCol>
              <CCol xs={'12'} md={'6'} className="mb-2">
                <CFormLabel className="col-sm-2 col-form-label w-100">Contacto</CFormLabel>
                <ContactInputs values={formDta} onChange={handleChange} />
              </CCol>
            </CRow>
            <CCol xs="12">
              <CRow>
                {formDta.videos.map((it, index) => {
                  return (
                    <CCol className='mb-1' key={`video_times_cont_${index}`} xs="12" sm="6" md="4" lg="3">
                      <div
                        className="bg-white text-black px-3 py-2"
                        style={{ borderRadius: '8px' }}
                      >
                        <p className="fw-bolder mb-1 text-primary ">{it.text}</p>
                        <CCollapse visible={it.value === activeRule}>
                          <CCard className="mt-3">
                            <CCardBody>
                              <CRow className="">
                                <CCol className="pe-0">
                                  <CFormSelect
                                    value={startInput}
                                    onChange={(e) => {
                                      const value = parseInt(e.target.value)
                                      setStartInput(value)
                                    }}
                                    label="Desde:"
                                    aria-label="desde"
                                    options={times.map((time,index)=>({value:index, label:time}))}
                                  />
                                </CCol>
                                <CCol className="ps-0">
                                  <CFormSelect
                                    value={endInput}
                                    onChange={(e) => {
                                      const value = parseInt(e.target.value)
                                      setEndInput(value)
                                    }}
                                    label="Hasta:"
                                    aria-label="hasta"
                                    options={times.map((time,index)=>({value:index, label:time}))}
                                  />
                                </CCol>
                              </CRow>
                            </CCardBody>
                          </CCard>
                        </CCollapse>
                        <CCol xs="12">
                          <CButton
                            onClick={() => {
                              if (it.value !== activeRule) {
                                setActiveRule(it.value)
                              } else {
                                handleAddNewRule(it.value)
                              }
                            }}
                            className="w-100 mt-2 text-white "
                            color="primary"
                            type="button"
                          >
                            {it.value === activeRule ? 'Agregar' : 'Añadir horario'}
                          </CButton>
                        </CCol>
                        <p className="">Horarios</p>
                        <CRow className='justify-content-around  '>
                          {formDta.rules?.[it.value] && formDta.rules?.[it.value].length > 0
                            ? formDta.rules?.[it.value].map((rule, index) => {
                                return (
                                  <CCol onClick={(e)=>{
                                    handleAddRemoveRule(it.value, index)
                                  }} xs='auto' className='text-center px-2 py-1 rule-time-option position-relative mb-1' style={{minWidth:'75px'}} key={`${it.value}_rule_${index}`}>
                                    {times[rule.start]} - {times[rule.end]}
                                    <div className='icon-delete'><CIcon size='sm' icon={cilTrash} customClassName="nav-icon" /></div>
                                  </CCol>
                                )
                              })
                            : 'Todo el dia'}
                        </CRow>
                      </div>
                    </CCol>
                  )
                })}
              </CRow>
            </CCol>
            <div className="d-flex justify-content-end ">
              <CButton
                disabled={loading}
                onClick={handleSubmit}
                color="primary"
                className="text-white"
              >
                {params.id ? 'Editar' : 'Crear'}
              </CButton>
            </div>
          </CForm>
        </CCardBody>

        {loading && (
          <div
            className="position-absolute bg-black  opacity-75 d-flex  justify-content-center align-items-center"
            style={{ inset: 0 }}
          >
            <CSpinner color="primary" />
          </div>
        )}
      </CCard>
    </div>
  )
}

function ContactInputs({ values, onChange }) {
  return (
    <>
      <div className="mb-1">
        <CInputGroup className="mb-3">
          <CInputGroupText></CInputGroupText>
          <CFormInput
            className="opacity-75"
            onChange={onChange}
            value={values.instagram}
            name="instagram"
            type="text"
            placeholder="Instagram"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CInputGroup>
      </div>
      <div className="mb-1">
        <CInputGroup className="mb-3">
          <CInputGroupText></CInputGroupText>
          <CFormInput
            className="opacity-75"
            onChange={onChange}
            value={values.facebook}
            name="facebook"
            type="text"
            placeholder="Facebook"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CInputGroup>
      </div>
      <div className="mb-1">
        <CInputGroup className="mb-3">
          <CInputGroupText></CInputGroupText>
          <CFormInput
            className="opacity-75"
            onChange={onChange}
            value={values.twitter}
            name="twitter"
            type="text"
            placeholder="Twitter (me reuso a llamarlo x)"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CInputGroup>
      </div>
      <div className="mb-1">
        <CInputGroup className="mb-3">
          <CInputGroupText></CInputGroupText>
          <CFormInput
            className="opacity-75"
            onChange={onChange}
            value={values.localPhone}
            name="localPhone"
            type="text"
            placeholder="Teléfono Fijo"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CInputGroup>
      </div>
      <div className="mb-1">
        <CInputGroup className="mb-3">
          <CInputGroupText></CInputGroupText>
          <CFormInput
            className="opacity-75"
            onChange={onChange}
            value={values.mobilePhone}
            name="mobilePhone"
            type="text"
            placeholder="Celular"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CInputGroup>
      </div>
      <div className="mb-1">
        <CInputGroup className="mb-3">
          <CInputGroupText></CInputGroupText>
          <CFormInput
            className="opacity-75"
            onChange={onChange}
            value={values.direction}
            name="direction"
            type="text"
            placeholder="Ubicación"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CInputGroup>
      </div>
    </>
  )
}

ContactInputs.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
}
export default CreateScreensView
