

import { SET_DATA_USER, SET_NOTIFICATIONS, SET_TOKEN_USER, SET_SIGN_OUT } from "../const"

export const user = (state = {}, action) => {
  switch (action.type) {
    case SET_TOKEN_USER:
      return { ...state, token: action.value }
    case SET_DATA_USER:
      return { ...state, data: action.value }
    case SET_NOTIFICATIONS:
      return { ...state, notifications: action.value }
    case SET_SIGN_OUT:
      return { ...state, notifications: [], data: undefined, token:'' }
    default:
      return state
  }
}

export const getTokenUser = (state) => {
  return state.user.token
}
export const getDataUser = (state) => {
  return state.user.data || {}
}
export const getNotifications = (state) => {
  return state.user.notifications
}
