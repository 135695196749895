import React from 'react'
import { connect } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import {
  //cilAccountLogout,
  //cilBasket,
  //cilAccountLogout,
  //cilApplicationsSettings,
  //cilBasket,
  cilMenu,
  //cilMoon,
  //cilSun,
} from '@coreui/icons'

import { AppBreadcrumb } from './index'

import { logo } from 'src/assets/brand/logo'
import { getAsideShow, getSidebarShow, getTheme } from 'src/Redux/reducers/page_status.reducer'
import PropTypes from 'prop-types'
import { setAsideShow, setSidebarShow, setTheme } from 'src/Redux/actions/page_status.actions'
import { setSignOut } from 'src/Redux/actions/user.actions'
import { AppHeaderDropdown } from './header'
//import { sbClient } from 'src/utils/config/supabase-configuration'
import { createClient } from '@supabase/supabase-js'

const AppHeader = ({
  theme = 'light',
  sidebarShow,
  asideShow,
  setSidebarShow = () => {},
  setAsideShow = () => {},
  setTheme = () => {},
  setSignOut = () => {},
}) => {
  theme === 'dark'
    ? document.body.classList.add('dark-theme')
    : document.body.classList.remove('dark-theme')

  const handleLogOut = async (e) => {
    e.preventDefault()
    try {
      //TODO: log out
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      
      await sbClient.auth.signOut()
      setSignOut()
    } catch (error) {
      console.log(error)
    }
  }
  /*
  const notificationsExample = [
    {
      onClick: () => {},
      msg: 'Nuevo Usuario',
      icon: cilBasket,
      color: 'warning',
    },
    {
      onClick: () => {},
      msg: 'nuevo Usuario',
      icon: cilAccountLogout,
      color: 'danger',
    },
  ]
*/
  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => {
            setSidebarShow(!sidebarShow)
          }}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand>

        <CHeaderNav className="ms-3 me-4">
          <AppHeaderDropdown onLogOut={handleLogOut} notificationsCount={0} />
        </CHeaderNav>

        {/*
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to="/dashboard" component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto me-4">
          <CButtonGroup aria-label="Theme switch">
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-light-theme"
              autoComplete="off"
              label={<CIcon icon={cilSun} />}
              checked={theme === 'default'}
              onChange={() => {
                setTheme('light')
              }}
            />
            <CFormCheck
              type="radio"
              button={{ color: 'primary' }}
              name="theme-switch"
              id="btn-dark-theme"
              autoComplete="off"
              label={<CIcon icon={cilMoon} />}
              checked={theme === 'dark'}
              onChange={() => {
                setTheme('dark')
              }}
            />
          </CButtonGroup>
        </CHeaderNav>
        <CHeaderNav>
          <AppHeaderDropdownNotif notifications={notificationsExample} />
        </CHeaderNav>
        <CHeaderToggler
          className="px-md-0 me-md-3"
          onClick={() => {
            setAsideShow(!asideShow)
          }}
        >
          <CIcon icon={cilApplicationsSettings} size="lg" />
        </CHeaderToggler>
        */}
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

const mapStateToProps = (state) => {
  return {
    theme: getTheme(state),
    sidebarShow: getSidebarShow(state),
    asideShow: getAsideShow(state),
  }
}
const mapDispatchToProps = (dispatch) => ({
  setSidebarShow: (value) => dispatch(setSidebarShow(value)),
  setAsideShow: (value) => dispatch(setAsideShow(value)),
  setTheme: (value) => dispatch(setTheme(value)),
  setSignOut: (value) => dispatch(setSignOut(value)),
})

AppHeader.propTypes = {
  theme: PropTypes.string,
  sidebarShow: PropTypes.bool,
  asideShow: PropTypes.bool,
  setSidebarShow: PropTypes.func,
  setAsideShow: PropTypes.func,
  setTheme: PropTypes.func,
  setSignOut: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
