import React from 'react'
import { protectComponent } from 'src/utils/protectComponent'
import { AppAside, AppContent, AppSidebar,  AppHeader } from '../components/index'

const LayoutWithoutFooter = () => {
  return (
    <>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <AppContent />
        </div>
      </div>
      <AppAside />
    </>
  )
}

export default protectComponent(LayoutWithoutFooter)
