import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import routesPaths from '../Routes-paths'
import Login from 'src/views/defaultPages/login/Login'
//import Login from 'src/views/defaultPages/login/Login.Supabase'
import Register from 'src/views/defaultPages/register/Register'
//import Dashboard from 'src/views/dashboard/Dashboard'
//import ChartsExamples from 'src/views/ChartsExamples'
import ScreensViews from 'src/views/Screens.view'
import CreateScreensView from 'src/views/CreateScreen.views'
import VideosView from 'src/views/Videos.view'
import SlidesView from 'src/views/Slides.view'
import LayoutWithoutFooter from 'src/layout/LayoutWithoutFooter'
import BasicScreen from 'src/views/ScreensTemplates/Basic.screen'
import FullscreenScreen from 'src/views/ScreensTemplates/Fullscreen.screen'
import LayoutAdminWithoutFooter from 'src/layout/LayoutAdminWithoutFooter'
import UsersAdminView from 'src/views/admin/Users.admin.view'
const Page500 = React.lazy(() => import('src/views/defaultPages/page500/Page500'))
const Page404 = React.lazy(() => import('src/views/defaultPages/page404/Page404'))

const AppRoute = [
  {
    path: '',
    element: <Outlet />,
    name: 'base',
    children: [
      {
        path: routesPaths.dashboard.base,
        name: 'Dashboard',
        element: <LayoutWithoutFooter />,
        children: [
          {
            exact: true,
            path: routesPaths.dashboard.screens,
            name: 'Pantallas',
            element: <ScreensViews />,
          },
          {
            exact: true,
            path: routesPaths.dashboard.videos,
            name: 'Videos',
            element: <VideosView />,
          },
          {
            exact: true,
            path: routesPaths.dashboard.slides,
            name: 'Slides',
            element: <SlidesView />,
          },
          {
            exact: true,
            path: routesPaths.dashboard.newScreen,
            name: 'Nueva Pantalla',
            element: <CreateScreensView />,
          },
          {
            exact: true,
            path: routesPaths.dashboard.editScreen(),
            name: 'Nueva Pantalla',
            element: <CreateScreensView />,
          },
          /*
          {
            path: routesPaths.dashboard.dashboard,
            name: 'Unidades de rendimiento',
            element: <Dashboard />,
          },
          {
            path: routesPaths.dashboard.charts,
            name: 'Producto a aplicar',
            element: <ChartsExa mples />,
          },
          */
          {
            path: routesPaths.dashboard.base,
            name: 'Dashboard',
            element: <Navigate to={routesPaths.dashboard.screens} />, //<Dashboard />,
          },
        ],
      },
      {
        path: routesPaths.admin.base,
        name: 'Admin Dashboard',
        element: <LayoutAdminWithoutFooter />,
        children: [
          {
            exact: true,
            path: routesPaths.admin.users,
            name: 'Pantallas',
            element: <UsersAdminView />,
          },
          /*
          {
            path: routesPaths.dashboard.dashboard,
            name: 'Unidades de rendimiento',
            element: <Dashboard />,
          },
          {
            path: routesPaths.dashboard.charts,
            name: 'Producto a aplicar',
            element: <ChartsExamples />,
          },
          */
          {
            path: routesPaths.admin.base,
            name: 'Dashboard',
            element: <Navigate to={routesPaths.admin.users} />, //<Dashboard />,
          },
        ],
      },
      {
        path: routesPaths.view.base,
        name: 'Admin',
        element: (
          <div className="screen-template">
            <Outlet />
          </div>
        ),
        children: [
          {
            path: routesPaths.view.basic(),
            name: 'view',
            element: <BasicScreen />, //<Dashboard />,
          },
          {
            path: routesPaths.view.fullscreen(),
            name: 'view',
            element: <FullscreenScreen />, //<Dashboard />,
          },
          {
            path: routesPaths.view.base,
            name: 'Dashboard',
            element: <Navigate to={routesPaths.login} />, //<Dashboard />,
          },
        ],
      },
      { path: routesPaths.login, name: 'Login', element: <Login /> },
      { path: routesPaths.register, name: 'Register', element: <Register /> },
      { path: routesPaths._404, name: '404', element: <Page404 /> },
      { path: routesPaths._500, name: '500', element: <Page500 /> },
      { path: '*', name: '', element: <Navigate to={routesPaths._404} /> },
      { path: '', name: '', element: <Navigate to={routesPaths._404} /> },
    ],
  },
]

export default AppRoute
