/* eslint-disable react-hooks/exhaustive-deps */
import { CButton, CButtonGroup, CCardImage, CSmartTable } from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

function SlideTable({ onDeleteItem = async () => {}, onRequestItems = () => {}, items, count, itemsPerPageHook=[] }) {
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const [itemsPerPage, setItemsPerPage] = itemsPerPageHook
  const timeout = useRef(null)
  const columns = [
    {
      key: 's3_img_key',
      label: '',
      filter: false,
      _style: {},
    },
    {
      key: 'title',
      label: 'Título',
      _style: {},
    },
    {
      key: 'description',
      label: 'Descripción',
      _style: {},
    },
    {
      key: 'created_at',
      label: 'Fecha de creación',
      filter: false,
      _style: {},
    },
    {
      key: 'more',
      label: '',
      filter: false,
    },
  ]
  useEffect(() => {
    onRequestItems(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const handleDelete = async (vdo) => {
    try {
      await onDeleteItem(vdo)
      onRequestItems(activePage, columnFilter, columnSorter, itemsPerPage)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <CSmartTable
      className="table-video"
      columns={columns}
      columnFilter={{
        external: true,
      }}
      columnSorter={{
        external: true,
      }}
      items={items}
      itemsPerPage={itemsPerPage}
      itemsPerPageSelect
      pagination={{
        external: true,
      }}
      paginationProps={{
        activePage: activePage,
        pages: Math.ceil(count / itemsPerPage) || 1,
      }}
      tableProps={{
        hover: true,
        responsive: true,
      }}
      onActivePageChange={(activePage) => setActivePage(activePage)}
      onColumnFilterChange={(filter) => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
          setActivePage(1)
          setColumnFilter(filter)
        }, 500)
      }}
      onItemsPerPageChange={(itemsPerPage) => {
        setActivePage(1)
        setItemsPerPage(itemsPerPage)
      }}
      onSorterChange={(sorter) => setColumnSorter(sorter)}
      scopedColumns={{
        s3_img_key: (item) => {
          return <td className="py-2">
            <CCardImage height={'100%'} src={`${process.env.REACT_APP_AWS_STORAGE_URL}${item.s3_img_key}`} />
          </td>
        },
        title: (item) => {
          return <td className="py-2 fw-bolder ">
            {item.title}
          </td>
        },
        description: (item) => {
          return <td className="py-2 slide-table-description ">
            <p>{item.description}</p>
          </td>
        },
        more: (item) => {
          return (
            <td className="py-2">
              <CButtonGroup className="w-100">
                <CButton
                  onClick={() => {
                    handleDelete(item)
                    
                  }}
                  size="sm"
                  color="secondary"
                >
                  Borrar
                </CButton>
              </CButtonGroup>
            </td>
          )
        },
      }}
    />
  )
}

SlideTable.propTypes = {
  onRequestItems: PropTypes.func,
  onDeleteItem: PropTypes.func,
  count: PropTypes.number,
  items: PropTypes.array,
  itemsPerPageHook: PropTypes.array,
}
export default SlideTable
