import React from 'react'
import { connect } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react-pro'
//import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

//import { logoNegative } from 'src/assets/brand/logo-negative'
//import { sygnet } from 'src/assets/brand/sygnet'
import Logo from 'src/assets/brand/Logo.png'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import PropTypes from 'prop-types'
import { getSidebarShow, getSideBarUnfoldable } from 'src/Redux/reducers/page_status.reducer'
import { setSidebarShow, setSideBarUnfoldable } from 'src/Redux/actions/page_status.actions'

const AppSidebar = ({
  admin,
  sidebarShow,
  sidebarUnfoldable,
  setSidebarShow = () => {},
  setSidebarUnfoldable = () => {},
}) => {
  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        setSidebarShow(visible)
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage className="sidebar-brand-full" width={'80%'} src={Logo} alt=''/>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>{!admin ? <AppSidebarNav items={navigation} /> : <></>}</SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => {
          setSidebarUnfoldable(!sidebarUnfoldable)
        }}
      />
    </CSidebar>
  )
}
//<CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
//<CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />

const mapStateToProps = (state) => {
  return {
    sidebarShow: getSidebarShow(state),
    sidebarUnfoldable: getSideBarUnfoldable(state),
  }
}
const mapDispatchToProps = (dispatch) => ({
  setSidebarShow: (value) => dispatch(setSidebarShow(value)),
  setSidebarUnfoldable: (value) => dispatch(setSideBarUnfoldable(value)),
})

AppSidebar.propTypes = {
  admin: PropTypes.bool,
  sidebarShow: PropTypes.bool,
  sidebarUnfoldable: PropTypes.bool,
  setSidebarShow: PropTypes.func,
  setSidebarUnfoldable: PropTypes.func,
}
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AppSidebar))
