import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCol,
  CForm,
  CFormInput,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from '@coreui/react-pro'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DropzoneInput from 'src/components/DropzoneInput'
import routesPaths from 'src/utils/Routes-paths'
//import { sbClient } from 'src/utils/config/supabase-configuration'
import { deleteFileInStorage, setFileInStorage } from 'src/utils/S3_Storage'
import useToast from 'src/utils/customHooks/useToast'
import { getDataUser } from 'src/Redux/reducers/user.reducer'
import { useSelector } from 'react-redux'
import { createClient } from '@supabase/supabase-js'
import SlideTable from 'src/components/tables/slides.table'
import { v4 as uuidv4 } from 'uuid'

function SlidesView() {
  const navigate = useNavigate()
  const addToast = useToast()
  const { user } = useSelector(getDataUser)
  const [newSlideDta, setNewSlideDta] = useState({
    title: '',
    description: '',
    img: undefined,
  })
  const [slides, setSlides] = useState([])
  const [slidesCount, setSlidesCount] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const requestFiles = async (
    activePage = 1,
    columnFilter = {},
    columnSorter = {},
    itemsPerPage = 10,
  ) => {
    try {
      const pageSQL = activePage - 1
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const orderObj = columnSorter.column
        ? {
            column: columnSorter?.column,
            asc: columnSorter.state === 'asc',
          }
        : {
            column: 'title',
            asc: true,
          }
      let data, count
      const { data: tempDta, error } = await sbClient
        .from('slides')
        .select()
        .eq('uploaded_by', user.id)
        .like('title', `%${columnFilter.title || ''}%`)
        .like('description', `%${columnFilter.description || ''}%`)
        .order(orderObj.column, { ascending: orderObj.asc })
        .range(pageSQL * itemsPerPage, (pageSQL + 1) * itemsPerPage)

      const { count: tempCount } = await sbClient
        .from('slides')
        .select('*', { count: 'exact', head: true })
        .eq('uploaded_by', user.id)
        .like('title', `%${columnFilter.title || ''}%`)
        .like('description', `%${columnFilter.description || ''}%`)
      if (error) navigate(routesPaths._500)
      data = tempDta
      count = tempCount
      setSlides(data)
      setSlidesCount(count)
    } catch (error) {
      console.error(error)
    }
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    setNewSlideDta((prev) => {
      return { ...prev, [name]: value }
    })
  }
  const handleDropFile = (e = []) => {
    if (e.length === 0) return
    setNewSlideDta((prev) => {
      return { ...prev, img: e[0] }
    })
  }
  const handleDelete = async (vdo) => {
    try {
      const key = vdo.s3_img_key
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const { error } = await sbClient.from('slides').delete().eq('id', vdo.id)
      if (error) return
      await deleteFileInStorage(key)
    } catch (error) {}
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      if (!newSlideDta.img || !newSlideDta.description) throw new Error('Faltan datos')
      const key =
        `public/${user.id}/images/` +
        `${newSlideDta.img.name}_`.replace('.mp4', '').substring(0, 15) +
        uuidv4().replace(/-/g, '_') +
        '.mp4'
      await setFileInStorage({
        file: newSlideDta.img,
        key: key,
      })
      const sbClient = createClient(
        process.env.REACT_APP_SUPABASE_URL,
        process.env.REACT_APP_SUPABASE_KEY,
      )
      const { error } = await sbClient.from('slides').insert({
        title: newSlideDta.title,
        s3_img_key: key,
        description: newSlideDta.description,
        uploaded_by: user.id,
      })

      if (error) throw error
      addToast({
        title: 'slide Subido exitosamente',
        body: '',
      })
      requestFiles()
      setNewSlideDta({
        title: '',
        description: '',
        img: undefined,
      })
    } catch (error) {
      let msg = typeof error === typeof '' ? error : error.message
      addToast({
        title: 'Parece que hubo un error',
        body: msg || 'Estamos trabajando en ello',
      })
    }
    setLoading(false)
  }
  return (
    <>
      <h1 className="mb-2">Slide</h1>
      <div className="mb-3">
        <CCard style={{ border: '2px solid #2829339f' }} className="flex-grow-1 position-relative">
          <CCardHeader>
            <CCardTitle>Ingrese slide</CCardTitle>
          </CCardHeader>

          <CCardBody className=" ">
            <CForm onSubmit={handleSubmit}>
              <CRow className="justify-content-center ">
                <CCol>
                  <div className="bg-white opacity-75 h-100" style={{ color: 'black' }}>
                    <DropzoneInput
                      loaded={
                        newSlideDta.img && (
                          <img height={'100px'} src={URL.createObjectURL(newSlideDta.img)} alt="" />
                        )
                      }
                      accept={{
                        'image/*': ['.jpeg', '.png'],
                      }}
                      onDrop={handleDropFile}
                    >
                      {newSlideDta.img ? (
                        <>{newSlideDta.img.name}</>
                      ) : (
                        <p>arrastra aquí la imagen</p>
                      )}
                    </DropzoneInput>
                  </div>
                </CCol>
                <CCol>
                  <CInputGroup className="mb-3">
                    <CInputGroupText id="basic-addon1"></CInputGroupText>
                    <CFormInput
                      onChange={handleChange}
                      className="opacity-75 text-black "
                      placeholder="Titulo"
                      name="title"
                      value={newSlideDta.title}
                      aria-describedby="basic-addon1"
                    />
                  </CInputGroup>
                  <CInputGroup>
                    <CInputGroupText></CInputGroupText>
                    <CFormTextarea
                      onChange={handleChange}
                      name="description"
                      value={newSlideDta.description}
                      className="opacity-75 text-black"
                      placeholder="Descripción"
                    ></CFormTextarea>
                  </CInputGroup>
                </CCol>
              </CRow>
              <span className="d-block">700x400</span>
              <div className="d-flex justify-content-end ">
                <CButton disabled={loading} type="submit" color="primary">
                  Crear
                </CButton>
              </div>
            </CForm>
          </CCardBody>
          {loading && (
            <div
              className="position-absolute bg-black  opacity-75 d-flex  justify-content-center align-items-center"
              style={{ inset: 0 }}
            >
              <CSpinner color="primary" />
            </div>
          )}
        </CCard>
      </div>
      <CCard>
        <CCardBody className="slides-table">
          {/*
            slides.map((slide, i) => {
            return (
              <CardHorizontal
                key={`slide_${i}`}
                img={`${process.env.REACT_APP_AWS_STORAGE_CLOUDFRONT_URL}${slide.s3_img_key}`}
                onDelete={() => {
                  handleDelete(slide)
                }}
                style={{ height: '150px', textTransform: 'capitalize' }}
                color="white"
                title={slide.title}
                description={slide.description}
                //note="creado hace 3 dias"
              />
            )
          })
          */}
          <SlideTable
            items={slides}
            count={slidesCount}
            onRequestItems={requestFiles}
            onDeleteItem={handleDelete}
            itemsPerPageHook={[itemsPerPage, setItemsPerPage]}
          />
        </CCardBody>
      </CCard>
    </>
  )
}
// xs={'12'} md={'6'}  lg={'4'} xl={'3'}
export default SlidesView
