import React from 'react'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { getDataUser } from '../Redux/reducers/user.reducer'
import PropTypes from 'prop-types'

export const protectComponent = (WrappedComponent, roles=['user']) => {
  const Validate = class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        level: true,
      }
    }
    async componentDidMount() {
    }
    render() {
      const {user} = this.props.user
      if (!user )  {
        return <Navigate from="*" to="/login" />
      }
      let items = roles.filter( function (tag) {
        return user.roles.includes(tag)
         
      });
      if(items.length===0 ){
        return <Navigate from="*" to="/login" />
      }
      const newProps = { ...this.props, /*token: token*/ }
      return <WrappedComponent {...newProps} />
    }
  }
  const mapDispatchToProps = (dispatch) => ({
  })

  Validate.propTypes = {
    user: PropTypes.object,
  }
  return connect((state) => ({ user: getDataUser(state) }), mapDispatchToProps)(Validate)
}
