import { CCarousel, CCarouselItem, CCol, CImage, CRow, CSpinner } from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import CardGeneral from 'src/components/Cards/CardGeneral'
//import imageExample from 'src/assets/images/react.jpg'
import { cilSync, cibFacebook, cibTwitter, cibInstagram, cibWhatsapp } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
//import { sbClient } from 'src/utils/config/supabase-configuration'
import PropTypes from 'prop-types'
import useInterval from 'src/utils/customHooks/useInterval'
import { fullScreenHighOrder } from 'src/utils/fullScreenHighorder'

const month = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SEP', 'OCT', 'NOV', 'DEC']
function BasicScreen({ screen }) {
  const [actualVideo, setActualVideo] = useState(0)
  const [actualHour, setActualHour] = useState(-1)
  const [videos, setVideos] = useState([])
  const videoPlayerRef = useRef(null)
  const videoPlayerFullscreenRef = useRef(null)

  useInterval(() => {
    const now = new Date()
    let hours = now.getHours()
    if (actualHour !== hours) setActualHour(hours)
  }, 60000,true)
  useEffect(() => {
    if (actualHour >= 0 && screen) {
      //setVideos(screen.videosURL)
      debugger
      setVideos(screen.videoByHours[actualHour])
    }
    return () => {}
  }, [actualHour, screen])

  useEffect(() => {
    if (videoPlayerRef.current && videoPlayerFullscreenRef.current && videos.length > 0) {
      videoPlayerFullscreenRef.current.style.display = 'none'
      videoPlayerRef.current.src = videos[actualVideo].src
      videoPlayerFullscreenRef.current.src = videos[actualVideo].src
      if (videos[actualVideo].fullscreen) {
        videoPlayerFullscreenRef.current.style.display = 'block'
        videoPlayerFullscreenRef.current.load()
        videoPlayerFullscreenRef.current.play()
      } else {
        videoPlayerFullscreenRef.current.style.display = 'none'
        videoPlayerRef.current.load()
        videoPlayerRef.current.play()
      }
    }

    return () => {}
  }, [actualVideo, videos])
/*
  useEffect(() => {
    const handleDetectClick = () => {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.play()
      }
    }
    window.addEventListener('keydown', handleDetectClick)
    return () => {
      window.removeEventListener('keydown', handleDetectClick)
    }
  }, [])
  */
  const handleCarrouselVideo = (e) => {
    if (videos.length > 1) {
      setActualVideo((prev) => {
        let next = prev + 1
        if (next >= videos.length) next = 0
        return next
      })
    } else if (videoPlayerRef.current && videos.length===1) {
      videoPlayerRef.current.src = videos[0].src
      videoPlayerRef.current.load()
      videoPlayerRef.current.play()
    }
  }
  if (!Boolean(screen)) {
    return (
      <div className="py-3 d-flex justify-content-center align-items-center">
        <CSpinner color="primary" />
      </div>
    )
  }
  return (
    <CRow className=" view-basic min-vh-100 m-0 position-relative ">
      <BasicScreenSidebar
        logo={process.env.REACT_APP_AWS_STORAGE_CLOUDFRONT_URL + screen.s3_logo_key}
        slides={screen.slides}
      />
      <CCol className="view-content pe-0">
        <div className="view-header">
          <CIcon icon={cilSync} size="3xl" />
          <h1 className="text-uppercase mx-2">{screen.title}</h1>
          <CIcon icon={cilSync} size="3xl" />
        </div>
        <div className="view-body">
          <video
            onClick={(e) => {
              videoPlayerRef.current.play()
            }}
            autoPlay
            playsInline
            ref={videoPlayerRef}
            width="100%"
            height="100%"
            type="video/mp4"
            onEnded={handleCarrouselVideo}
            onError={(e) => {
              console.error(e)
              handleCarrouselVideo()
            }}
          >
            Tu navegador no admite el elemento <code>video</code>.
          </video>
        </div>
        <div className="view-footer">
          <CCol xs="auto">
            <p className="me-2">
              <span className="me-1">
                <CIcon icon={cibFacebook} size="3xl" />
              </span>
              {screen.facebook}
            </p>
          </CCol>
          <CCol xs="auto">
            <p className="me-2">
              <span className="me-1">
                <CIcon icon={cibTwitter} size="3xl" />
              </span>
              @{screen.twitter}
            </p>
          </CCol>
          <CCol xs="auto">
            <p className="me-2">
              <span className="me-1">
                <CIcon icon={cibInstagram} size="3xl" />
              </span>
              @{screen.instagram}
            </p>
          </CCol>
          <CCol></CCol>
          <CCol lg={3} className="d-flex justify-content-center flex-column ">
            <p className="text-uppercase text-warning w-auto fw-bold  ">Lineas de atención</p>
            <p className="">
              <span className="me-1">
                <CIcon icon={cibWhatsapp} />
              </span>

              {screen.mobilePhone}
            </p>
            <p className="">{screen.localPhone}</p>
          </CCol>
          <CCol className="d-flex justify-content-end align-items-end " xs="auto">
            <p style={{ marginBottom: '0.2rem!important' }}>{screen.direction}</p>
          </CCol>
        </div>
      </CCol>
      <video
        className="position-absolute object-fit-fill p-0"
        style={{ inset: 0, display: 'none' }}
        ref={videoPlayerFullscreenRef}
        width="100%"
        height="100%"
        type="video/mp4"
        onEnded={handleCarrouselVideo}
        onError={(e) => {
          console.error(e)
          handleCarrouselVideo()
        }}
      >
        Tu navegador no admite el elemento <code>video</code>.
      </video>
    </CRow>
  )
}

function BasicScreenSidebar({ logo = '', slides = [] }) {
  const [relojData, setRelojData] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
    ampm: 'AM',
    date: 'JUN 1 de 2023',
  })
  useInterval(() => {
    const now = new Date()
    let hours = now.getHours()
    let minutes = now.getMinutes()
    let seconds = now.getSeconds()
    let ampm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    seconds = seconds < 10 ? '0' + seconds : seconds
    const newTimes = {
      hours,
      minutes,
      seconds,
      ampm: ampm,
      date: `${month[now.getMonth()]} ${now.getDate()} de ${now.getFullYear()}`,
    }
    setRelojData(newTimes)
  }, 500)
  return (
    <CCol md="4" lg="3" className=" view-sidebar p-0">
      <div className="h-100 d-flex flex-column  justify-content-between  ">
        <div className="view-logo clearfix pt-5 mb-3">
          <CImage align="center" rounded thumbnail src={logo} />
        </div>

        <CCarousel className="mx-lg-3" interval={slides.length === 1 ? false : 3000}>
          {slides.map((sld, i) => {
            return (
              <CCarouselItem key={`carouselItem_${i}`}>
                <CardGeneral
                  img={process.env.REACT_APP_AWS_STORAGE_CLOUDFRONT_URL + sld.s3_img_key}
                  title={sld.title}
                  description={sld.description}
                ></CardGeneral>
              </CCarouselItem>
            )
          })}
        </CCarousel>
        <div className="time-indicator mx-5 px-5 py-4">
          <CRow className="hour justify-content-center ">
            <CCol className="d-flex justify-content-center align-items-center  p-0" xs="auto">
              <p>
                {relojData.hours}
                <span>:</span>
              </p>
            </CCol>
            <CCol className="d-flex justify-content-center align-items-center  p-0" xs="auto">
              <p>
                {relojData.minutes}
                <span>:</span>
              </p>
            </CCol>
            <CCol
              className="p-0 d-flex flex-column  justify-content-center align-items-center"
              xs="auto"
            >
              <div className="lh-1 mt-2 fw-bolder ">{relojData.ampm}</div>
              <div className="lh-1  ">{relojData.seconds}</div>
            </CCol>
          </CRow>
          <div className="date mt-2 text-center ">{relojData.date}</div>
        </div>
      </div>
    </CCol>
  )
}
BasicScreenSidebar.propTypes = {
  logo: PropTypes.string,
  slides: PropTypes.array,
}
BasicScreen.propTypes = {
  screen: PropTypes.object,
}
export default fullScreenHighOrder(BasicScreen)
