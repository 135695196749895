import React from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setDataUser, setTokenUser } from 'src/Redux/actions/user.actions'
import { useState } from 'react'
import { sbClient } from 'src/utils/config/supabase-configuration'
import routesPaths from 'src/utils/Routes-paths'
import { useNavigate } from 'react-router-dom'

const Register = ({ setTokenUser, setDataUser }) => {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    repassword: '',
  })
  const [errorformData, setErrorFormData] = useState({
    email: false,
    password: false,
    repassword: false,
  })
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (errorformData.email || errorformData.password || errorformData.repassword) return
      //set register
      const {data: response} = await sbClient.auth.signUp({
        email: formData.email,
        password: formData.password,
        options: {
          redirectTo: process.env.REACT_APP_CURRENT_URL + routesPaths.dashboard.base,
          data: {
          }
        },
      })
      if(response.user) {
        navigate(routesPaths.dashboard.base,{replace:true})
      }
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorFormData({ ...errorformData, email: true })
      }
      console.log(error)
    }
  }
  const handleValidatePassword = async (data) => {
    const errors = { ...errorformData }
    if (data.password.length < 6) {
      errors.password = true
    } else if (data.password !== data.repassword) {
      errors.password = true
      errors.repassword = true
    } else {
      errors.password = false
      errors.repassword = false
    }
    setErrorFormData(errors)
  }
  const handleChange = ({ target: { name, value } }) => {
    const data = { ...formData }
    data[name] = value
    setFormData(data)
    if (name === 'password' || name === 'repassword') {
      console.log(data)
      handleValidatePassword(data)
    } else {
      setErrorFormData({ ...errorformData, email: false })
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCardGroup className="mx-4">
              <CCard>
                <CCardBody className="p-4">
                  <CForm onSubmit={handleSubmit}>
                    <h1>Register</h1>
                    <p className="text-medium-emphasis">Create your account</p>
                    {/* <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput placeholder="Username" autoComplete="username" />
                  </CInputGroup> */}
                    <CInputGroup className="mb-3">
                      <CInputGroupText>@</CInputGroupText>
                      <CFormInput
                        onChange={handleChange}
                        value={formData.email}
                        invalid={errorformData.email}
                        name="email"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={handleChange}
                        value={formData.password}
                        invalid={errorformData.password}
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="new-password"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={handleChange}
                        value={formData.repassword}
                        invalid={errorformData.repassword}
                        name="repassword"
                        type="password"
                        placeholder="Repeat password"
                        autoComplete="new-password"
                      />
                    </CInputGroup>
                    <div className="d-grid">
                      <CButton type="submit" color="success">
                        Create Account
                      </CButton>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => ({
  setTokenUser: (value) => dispatch(setTokenUser(value)),
  setDataUser: (value) => dispatch(setDataUser(value)),
})

Register.propTypes = {
  setTokenUser: PropTypes.func,
  setDataUser: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(Register)
/*
<LoginProviderCard
                title="Sign up"
                subtitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />
*/
