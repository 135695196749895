/* eslint-disable react-hooks/exhaustive-deps */
import {
  CButton,
  CButtonGroup,
  CCardBody,
  CCollapse,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CSmartTable,
} from '@coreui/react-pro'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

function VideosTable({
  onDeleteItem = async () => {},
  onEditItem = async () => {},
  onRequestItems = () => {},
  items,
  count,
  itemsPerPageHook = [],
}) {
  const [activePage, setActivePage] = useState(1)
  const [columnFilter, setColumnFilter] = useState({})
  const [columnSorter, setColumnSorter] = useState({})
  const [itemsPerPage, setItemsPerPage] = itemsPerPageHook
  const timeout = useRef(null)
  const [details, setDetails] = useState([])
  const [newTitleInput, setNewTitleInput] = useState('')
  const videosRefs = useRef({})
  const columns = [
    {
      key: 'title',
      label: 'Título',
      _style: {},
    },
    {
      key: 'fullscreen',
      label: 'Pantalla completa',
      filter: false,
      _style: {},
    },
    {
      key: 'created_at',
      label: 'Fecha de creación',
      filter: false,
      _style: {},
    },
    {
      key: 'more',
      label: '',
      filter: false,
    },
  ]
  useEffect(() => {
    onRequestItems(activePage, columnFilter, columnSorter, itemsPerPage)
  }, [activePage, columnFilter, columnSorter, itemsPerPage])

  const handleDelete = async (vdo) => {
    try {
      const key = vdo.s3_key
      if (!videosRefs.current[key]) return
      await onDeleteItem(vdo)
      const references = { ...videosRefs.current }
      delete references[key]
      videosRefs.current = references
      onRequestItems(activePage, columnFilter, columnSorter, itemsPerPage)
    } catch (error) {
      console.error(error)
    }
  }
  const handleToggleFullscreen = async (vdo) => {
    try {
      await onEditItem({...vdo, fullscreen: !vdo.fullscreen})
      onRequestItems(activePage, columnFilter, columnSorter, itemsPerPage)
    } catch (error) {
      console.error(error)
    }
  }
  const handleChangeTitle = async (vdo) => {
    try {
      if(newTitleInput.length <5) return
      await onEditItem({...vdo, title: newTitleInput})
      onRequestItems(activePage, columnFilter, columnSorter, itemsPerPage)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <CSmartTable
      className="table-video"
      columns={columns}
      columnFilter={{
        external: true,
      }}
      columnSorter={{
        external: true,
      }}
      items={items}
      itemsPerPage={itemsPerPage}
      itemsPerPageSelect
      pagination={{
        external: true,
      }}
      paginationProps={{
        activePage: activePage,
        pages: Math.ceil(count / itemsPerPage) || 1,
      }}
      tableProps={{
        hover: true,
        responsive: true,
      }}
      onActivePageChange={(activePage) => setActivePage(activePage)}
      onColumnFilterChange={(filter) => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
          setActivePage(1)
          setColumnFilter(filter)
        }, 500)
      }}
      onItemsPerPageChange={(itemsPerPage) => {
        setActivePage(1)
        setItemsPerPage(itemsPerPage)
      }}
      onSorterChange={(sorter) => setColumnSorter(sorter)}
      scopedColumns={{
        s3_key: (item) => {
          return <td className="py-2"></td>
        },
        fullscreen: (item) => {
          return <td className="py-2">{item.fullscreen ? 'Si' : 'No'}</td>
        },
        created_at: (item) => {
          return (
            <td className="py-2">
              {new Date(item.created_at).toLocaleDateString('en-GB', {
                hour12: false,
              })}
            </td>
          )
        },
        more: (item) => {
          return (
            <td className="py-2">
              <CButtonGroup className="w-100">
                <CButton
                  onClick={() => {
                    setDetails((prev) => {
                      if (prev === item.id) {
                        setNewTitleInput('')
                        return ''
                      }
                      setNewTitleInput(item.title)
                      return item.id
                    })
                    /*
                    setDetails((prev) => {
                      const next = [...prev]
                      const index = prev.findIndex((it) => `${it}` === `${item.id}`)
                      if (index >= 0) {
                        next.splice(index, 1)
                        return next
                      }
                      next.push(item.id)
                      return next
                    })
                    */
                  }}
                  size="sm"
                  color="secondary"
                >
                  {details === item.id ? 'Menos' : 'Más'}
                </CButton>
              </CButtonGroup>
            </td>
          )
        },
        details: (item) => {
          return (
            <CCollapse visible={details === item.id}>
              <CCardBody className="p-3">
                      <CInputGroup className="mb-3">
                        <CInputGroupText id="basic-addon1"></CInputGroupText>
                        <CFormInput
                          value={newTitleInput}
                          name="title"
                          onChange={(e)=>{setNewTitleInput(e.target.value)}}
                          placeholder="Titulo "
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                        />
                  <CButton
                    type="button"
                    onClick={() => {
                      handleChangeTitle(item)
                    }}
                    color="secondary"
                  >
                    Guardar
                  </CButton>
                      </CInputGroup>
                <CButtonGroup className="w-100 my-1">
                  <CButton
                    type="button"
                    onClick={() => {
                      handleDelete(item)
                    }}
                    color="secondary"
                  >
                    Eliminar
                  </CButton>
                  <CButton
                    type="button"
                    onClick={() => {
                      handleToggleFullscreen(item)
                    }}
                    color="primary"
                  >
                    {item.fullscreen
                      ? 'Mostrar en pantalla normal'
                      : 'Mostrar en pantalla completa'}
                  </CButton>
                </CButtonGroup>
                <div className="card-video-top">
                  <video
                    controls
                    ref={(currentRef) => {
                      videosRefs.current = { ...videosRefs.current, [item.s3_key]: currentRef }
                    }}
                    src={`${process.env.REACT_APP_AWS_STORAGE_URL}${item.s3_key}`}
                  ></video>
                </div>
              </CCardBody>
            </CCollapse>
          )
        },
      }}
    />
  )
}

VideosTable.propTypes = {
  onRequestItems: PropTypes.func,
  onDeleteItem: PropTypes.func,
  onEditItem: PropTypes.func,
  count: PropTypes.number,
  items: PropTypes.array,
  itemsPerPageHook: PropTypes.array,
}
export default VideosTable
