import React, { Suspense } from 'react'
import { Outlet, } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react-pro'

// routes config
//import routes from '../routes'

const AppContent = () => {
  return (
    <CContainer lg>
      <Suspense fallback={<CSpinner color="primary" />}>
        <Outlet />
      </Suspense>
    </CContainer>
  )
}
/*
        <Routes>
          {routes.map((route, idx) => {
            const props = route.props ? { ...route.props } : {}
            return (
              route.element && (
                <Route
                  {...props}
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  element={<route.element />}
                />
              )
            )
          })}
          
        </Routes>
*/
export default React.memo(AppContent)
