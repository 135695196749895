/* eslint-disable react-hooks/exhaustive-deps */
import { CButton, CButtonGroup, CCol, CRow } from '@coreui/react-pro'
import React, { useEffect, useState } from 'react'
import CardGeneral from 'src/components/Cards/CardGeneral'
import { useNavigate } from 'react-router-dom'
import routesPaths from 'src/utils/Routes-paths'
//import { sbClient } from 'src/utils/config/supabase-configuration'
import { getDataUser } from 'src/Redux/reducers/user.reducer'
import { useSelector } from 'react-redux'
import CIcon from '@coreui/icons-react'
import { cilPlus } from '@coreui/icons'
import { createClient } from '@supabase/supabase-js'
import useToast from 'src/utils/customHooks/useToast'

function ScreensView() {
  const navigate = useNavigate()
  const [screens, setScreens] = useState([])
  const addToast = useToast()
  const { user } = useSelector(getDataUser)

  const requestFiles = async () => {
    try {
      //const newVideos = await readPublicFiles()
    const sbClient = createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_KEY)
      const { data, error } = await sbClient.from('screen').select().eq('uploaded_by', user.id)
      if (error) navigate(routesPaths._500)
      setScreens(data)
    } catch (error) {}
  }
  useEffect(() => {
    requestFiles()
    return () => {}
  }, [])
  
  const copyToClipboard = async (e) => {
    try {
        let textField = document.createElement('textarea')
        const url =  `${process.env.REACT_APP_CURRENT_URL}${e}`
        textField.innerText = url
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        addToast({
          title: 'Link copiado',
          body: '',
        })
    } catch (error) {
        //console.log(error
    }
  };
  return (
    <>
      <h1 className="mb-2">Pantallas</h1>
      <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 2 }} lg={{ cols: 3 }} xl={{ cols: 4 }}>
        <CCol>
          <CardGeneral
            className="card-add-screen"
            onClickCard={() => {
              navigate(routesPaths.dashboard.newScreen)
            }}
            //img={exampleImg}
            title="Nueva pantalla"
          >
            <div className="flex-grow-1  d-flex justify-content-center  align-items-center ">
              <CIcon icon={cilPlus} size="3xl" />
            </div>
          </CardGeneral>
        </CCol>
        {screens.map((it, i) => {
          return (
            <CCol key={`screen_${i}`}>
              <CardGeneral title={it.title} description="una descripción de la pantalla">
                <CButtonGroup className="d-flex justify-content-end ">
                  <CButton
                    onClick={() => {
                      navigate(routesPaths.dashboard.editScreen(it.id))
                    }}
                    className="text-white"
                    type={'button'}
                    color="primary"
                  >
                    Editar
                  </CButton>
                  <CButton
                    onClick={() => {
                      copyToClipboard(routesPaths.view.basic(it.id))
                    }}
                    className="text-white"
                    type={'button'}
                    color="info"
                  >
                    Pantalla básica
                  </CButton>
                  <CButton
                    onClick={() => {
                      copyToClipboard(routesPaths.view.fullscreen(it.id))
                    }}
                    className="text-white"
                    type={'button'}
                    color="secondary"
                  >
                    Pantalla Completa
                  </CButton>
                </CButtonGroup>
              </CardGeneral>
            </CCol>
          )
        })}
      </CRow>
    </>
  )
}
// xs={'12'} md={'6'}  lg={'4'} xl={'3'}
export default ScreensView
