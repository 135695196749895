/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {  useNavigate } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react-pro'
import CIcon from '@coreui/icons-react'
import { cilLockLocked } from '@coreui/icons'
import { useState } from 'react'
import { setDataUser, setTokenUser } from 'src/Redux/actions/user.actions'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { sbClient } from 'src/utils/config/supabase-configuration'
import routesPaths from 'src/utils/Routes-paths'
import { getDataUser } from 'src/Redux/reducers/user.reducer'

const Login = ({ setTokenUser, setDataUser }) => {
  const navigate = useNavigate()
  const {user} = useSelector(getDataUser)
  useEffect(() => {
    if(user?.id) {
      navigate(routesPaths.dashboard.base, { replace: true })
    }
  
    return () => {
      
    }
  }, [])
  
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [errorformData, setErrorFormData] = useState({
    email: false,
    password: false,
  })
  const handleSubmit = async (e) => {
    e.preventDefault()
    
    try {
      if (errorformData.email || errorformData.password ) return
      //TODO: LOGIN
      const {data: response} = await sbClient.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
        options: {
          redirectTo: process.env.REACT_APP_CURRENT_URL + routesPaths.dashboard.base,
        },
      })
      if(response.user) {
        navigate(routesPaths.dashboard.base,{replace:true})
      }
    } catch (error) {
      console.log(error)
    }
  }
  const handleValidatePassword = async (data) => {
    const errors = { ...errorformData }
    if (data.password.length < 6) {
      errors.password = true
    } else {
      errors.password = false
    }
    setErrorFormData(errors)
  }
  const handleChange = ({ target: { name, value } }) => {
    const data = { ...formData }
    data[name] = value
    setFormData(data)
    if (name === 'password') {
      handleValidatePassword(data)
    }
  }
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={10} lg={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-center  align-items-center ">
                      <div
                        style={{ height: '150px', width: '150px', borderRadius: '100%' }}
                        className="d-block bg-white mb-3"
                      ></div>
                    </div>
                    <h1 className="text-center ">Login</h1>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>@</CInputGroupText>
                      <CFormInput
                        onChange={handleChange}
                        value={formData.email}
                        invalid={errorformData.email}
                        name="email"
                        type="email"
                        placeholder="Email"
                        autoComplete="email"
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        onChange={handleChange}
                        value={formData.password}
                        invalid={errorformData.password}
                        name="password"
                        type="password"
                        placeholder="Contraseña"
                        autoComplete="new-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol className="d-flex justify-content-end " xs={6}>
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>

                      {/* 
                      <CCol xs={6} className="text-right">
                        <Link to="/register">
                          <CButton color="primary" className="" active tabIndex={-1}>
                            Register
                          </CButton>
                        </Link>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> 
                      */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => ({
  setTokenUser: (value) => dispatch(setTokenUser(value)),
  setDataUser: (value) => dispatch(setDataUser(value)),
})

Login.propTypes = {
  setTokenUser: PropTypes.func,
  setDataUser: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
