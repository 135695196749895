import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
  //ListObjectsV2Command,
  //DeleteObjectsCommand,
  //GetObjectCommand,
  //DeleteObjectCommand,
} from '@aws-sdk/client-s3'

const env_values = {
  AWS_S3_STORAGE_BUCKET_NAME: process.env.REACT_APP_AWS_S3_STORAGE_BUCKET_NAME,
  AWS_S3_STORAGE_BUCKET_REGION: process.env.REACT_APP_AWS_S3_STORAGE_BUCKET_REGION,
  AWS_S3_STORAGE_PUBLIC_KEY: process.env.REACT_APP_AWS_S3_STORAGE_PUBLIC_KEY,
  AWS_S3_STORAGE_SECRET_KEY: process.env.REACT_APP_AWS_S3_STORAGE_SECRET_KEY,
};
const client = new S3Client({
  region: env_values.AWS_S3_STORAGE_BUCKET_REGION,
  credentials: {
    accessKeyId: env_values.AWS_S3_STORAGE_PUBLIC_KEY,
    secretAccessKey: env_values.AWS_S3_STORAGE_SECRET_KEY,
  },
});

export async function setFileInStorage({ file, key }, privacy = "private") {
  const input = {
    // PutObjectRequest
    ACL: privacy, //"private" || "public-read" || "public-read-write" || "authenticated-read" || "aws-exec-read" || "bucket-owner-read" || "bucket-owner-full-control",
    Body: file,
    Bucket: env_values.AWS_S3_STORAGE_BUCKET_NAME, // required
    Key: `${key}`, // required
  };
  const command = new PutObjectCommand(input);
  return await client.send(command);
}


export async function deleteFileInStorage(key) {
  const input = { // DeleteObjectRequest
    Bucket:  env_values.AWS_S3_STORAGE_BUCKET_NAME, // required
    Key: key, // required
  };
  const command = new DeleteObjectCommand(input);
  return await client.send(command);
}
/*
async function readFileInStorage(idFile) {
  const command = new GetObjectCommand({
    Bucket: env_values.AWS_S3_STORAGE_BUCKET_NAME, // required
    Key: idFile,
  });
  return await client.send(command);
}
*/
