/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useEffect } from 'react'
import { useRoutes } from 'react-router-dom'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { setDataUser, setTokenUser } from './Redux/actions/user.actions'
import AppRoute from './utils/config/app-routes'
import { sbClient } from './utils/config/supabase-configuration'
//import routesPaths from './utils/Routes-paths'
import { useState } from 'react'
import { ToastContextProvider } from './utils/config/toast-configuration'

function RoutesComponent() {
  let element = useRoutes(AppRoute)
  //let location = useLocation()

  return element
}
const loading = (
  <div className="py-3 d-flex justify-content-center align-items-center">
    <CSpinner color="primary" />
  </div>
)

function App({ setTokenUser = () => {}, setDataUser = () => {} }) {
  //const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {

    const {
      data: { subscription },
    } = sbClient.auth.onAuthStateChange(async (_event, session) => {
      if (session) {
        setTokenUser(session.access_token)
        const {data} = await sbClient.from('user')
        .select()
        .eq('id', session.user.id)
        
        setDataUser({session, user : data[0]})
      } else {
        setTokenUser('')
        setDataUser(undefined)
      }
      if(!loaded) setLoaded(true)
    })

    return () => subscription.unsubscribe()
  }, [])

  if (!loaded) {
    return loading
  }
  return (
    <>
      <Suspense fallback={loading}>
        <ToastContextProvider>
          <RoutesComponent />
        </ToastContextProvider>
      </Suspense>
    </>
  )
}
/*
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
        */
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => ({
  setTokenUser: (value) => dispatch(setTokenUser(value)),
  setDataUser: (value) => dispatch(setDataUser(value)),
})
App.propTypes = {
  setTokenUser: PropTypes.func,
  setDataUser: PropTypes.func,
}
export default connect(mapStateToProps, mapDispatchToProps)(App)
