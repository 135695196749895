import React, { useEffect, useRef, useState } from 'react'
import { fullScreenHighOrder } from 'src/utils/fullScreenHighorder'
import PropTypes from 'prop-types'
import useInterval from 'src/utils/customHooks/useInterval'


/**
 * 
 * @returns onEnded={(e) => {
          
        }}
 */
function FullscreenScreen({screen}) {
  const [actualVideo, setActualVideo] = useState(0)
  const [actualHour, setActualHour] = useState(-1)

  const [videos, setVideos] = useState([])
  const videoPlayerRef = useRef(null)
  useInterval(() => {
    
    const now = new Date()
    let hours = now.getHours()
    if(actualHour !== hours) setActualHour(hours)
  }, 60000,true)

  useEffect(() => {
    
    if (actualHour>=0 && screen) {
      //setVideos(screen.videosURL)
      setVideos(screen.videoByHours[actualHour])
    }
    return () => {}
  }, [actualHour,screen])


  useEffect(() => {
    
    if (videoPlayerRef.current && videos.length>0) {
      videoPlayerRef.current.pause()
      videoPlayerRef.current.src = videos[actualVideo].src
      videoPlayerRef.current.load()
      videoPlayerRef.current.play()
    }

    return () => {}
  }, [actualVideo, videos])
/*
  useEffect(() => {
    const handleDetectClick = () => {
      if (videoPlayerRef.current) {
        videoPlayerRef.current.play()
      }
    }
    window.addEventListener('keydown', handleDetectClick)
    return () => {
      window.removeEventListener('keydown', handleDetectClick)
    }
  }, [])
  */
  const handleCarrouselVideo = (e) => {
    if(videos.length >1 ) {
      setActualVideo((prev) => {
        let next = prev + 1
        if (next >= videos.length) next = 0
        return next
      })
    } else if(videoPlayerRef.current){
      videoPlayerRef.current.load()
      videoPlayerRef.current.play()

    }
  }
  return (
    <div className="view-fullscreen">
      <video
        ref={videoPlayerRef}
        width="100%"
        height="100%"
        //src={videos[actualVideo]}
        type="video/mp4"
        autoPlay
        playsInline
        


        onEnded={handleCarrouselVideo}
      onError={(e)=>{
        console.log('error',e)
        handleCarrouselVideo()
      }}
      >
        Tu navegador no admite el elemento <code>video</code>.
      </video>
    </div>
  )
}

FullscreenScreen.propTypes = {
  screen: PropTypes.object
}
export default fullScreenHighOrder(FullscreenScreen)
