import React from 'react'
import { connect } from 'react-redux'
import { CCloseButton, CSidebar, CSidebarHeader } from '@coreui/react-pro'
import PropTypes from 'prop-types'
import { getAsideShow } from 'src/Redux/reducers/page_status.reducer'
import { setAsideShow } from 'src/Redux/actions/page_status.actions'

const AppAside = ({ asideShow, setAsideShow = () => {} }) => {
  return (
    <CSidebar
      colorScheme="light"
      size="lg"
      overlaid
      placement="end"
      visible={asideShow}
      onVisibleChange={(visible) => {
        setAsideShow(visible)
      }}
    >
      <CSidebarHeader className="bg-transparent">
        <CCloseButton
          className="float-end"
          onClick={() => {
            setAsideShow(false)
          }}
        />
      </CSidebarHeader>
    </CSidebar>
  )
}

const mapStateToProps = (state) => {
  return {
    asideShow: getAsideShow(state),
  }
}
const mapDispatchToProps = (dispatch) => ({
  setAsideShow: (value) => dispatch(setAsideShow(value)),
})

AppAside.propTypes = {
  asideShow: PropTypes.bool,
  setAsideShow: PropTypes.func,
}
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(AppAside))
