
import { SET_ASIDE_SHOW, SET_SIDEBAR_SHOW, SET_SIDEBAR_UNFOLDABLE, SET_THEME } from '../const'

export const pageStatus = (state = {}, action) => {
  switch (action.type) {
    case SET_SIDEBAR_SHOW:
      return { ...state, sidebarShow: action.value }
    case SET_ASIDE_SHOW:
      return { ...state, asideShow: action.value }
    case SET_SIDEBAR_UNFOLDABLE:
      return { ...state, sidebarUnfoldable: action.value }
    case SET_THEME:
      return { ...state, theme: action.value }
    default:
      return state
  }
}

export const getSidebarShow = (state) => {
  return state.pageStatus.sidebarShow
}
export const getAsideShow = (state) => {
  return state.pageStatus.asideShow
}
export const getSideBarUnfoldable = (state) => {
  return state.pageStatus.sidebarUnfoldable
}
export const getTheme = (state) => {
  return state.pageStatus.theme
}
