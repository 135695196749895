import { CToast, CToastBody, CToastHeader, CToaster } from '@coreui/react-pro'
import React, { createContext, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'

const toastContext = createContext()

export default toastContext

export function ToastContextProvider({ children }) {
  const [toast, setToast] = useState([])
  useEffect(() => {
    let timer
    if (toast.length > 0) {
      timer = setTimeout(() => setToast((prevToast) => prevToast.slice(1)), 5000)
    }
    return () => {
      timer && clearTimeout(timer)
    }
  }, [toast])
  const addToast = useCallback(
    function ({
      title = 'toast',
      subtitle = '',
      icon = (
        <svg
          className="rounded me-2"
          width="20"
          height="20"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid slice"
          focusable="false"
          role="img"
        >
          <rect width="100%" height="100%" fill="#f74344"></rect>
        </svg>
      ),
      body = '',
    }) {
      const toastJsx = (
        <CToast
          id={`toast-card-id-${toast.length + 1}`}
          key={`toast-card-${toast.length + 1}`}
          autohide={false}
          visible={true}
        >
          <CToastHeader closeButton>
            <div className="d-inline">{icon}</div>
            <div className="fw-bold me-auto">{title}</div>
            <small>{subtitle}</small>
          </CToastHeader>
          <CToastBody>{body}</CToastBody>
        </CToast>
      )
      setToast((prevToast) => [...prevToast, toastJsx])
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setToast],
  )
  return (
    <toastContext.Provider value={addToast}>
      {children}
      <CToaster>
        {toast}
      </CToaster>
    </toastContext.Provider>
  )
}
ToastContextProvider.propTypes = {
  children: PropTypes.any,
  setDataUser: PropTypes.func,
}
