import {
  //CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardImage,
  CCardImageOverlay,
  CCardText,
  CCardTitle,
} from '@coreui/react-pro'
import PropTypes from 'prop-types'
import React from 'react'

function CardGeneral({
  className = '',
  img = '',
  title_image,
  description_image,
  title,
  description,
  footer,
  header,
  onClickCard,
  children
}) {
  return (
    <CCard
      onClick={onClickCard}
      className={`mb-3 bg-dark text-white h-100  ${Boolean(onClickCard) ? 'cursor-pointer' : ''} ${className}`}
    >
      {img ? <CCardImage orientation="top" src={img} /> : <></>}
      {(title_image || description_image) && (
        <CCardImageOverlay>
          {title_image && <CCardTitle>{title_image}</CCardTitle>}
          {description_image && <CCardText>{description_image}</CCardText>}
        </CCardImageOverlay>
      )}
      {header && <CCardHeader>{header}</CCardHeader>}
      <CCardBody>
          {title && <CCardTitle>{title}</CCardTitle>}
          {description && <CCardText>{description}</CCardText>}
          {children}
        </CCardBody>
      {footer && <CCardFooter>{footer}</CCardFooter>}
    </CCard>
  )
}

CardGeneral.propTypes = {
  img: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  header: PropTypes.string,
  footer: PropTypes.string,
  title_image: PropTypes.string,
  description_image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClickCard: PropTypes.func,
}
export default CardGeneral
