import React from 'react'
import {
  //CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react-pro'
import { cilMenu, cilBell, cilLockLocked, cilSettings, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import PropTypes from 'prop-types'

const AppHeaderDropdown = ({ notificationsCount = 0, onLogOut = () => {} }) => {
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CIcon icon={cilMenu} size="lg" className="my-1 mx-2" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownHeader className="bg-secondary dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownItem href="#">
          <CIcon icon={cilBell} className="me-2" />
          Notifications
          <CBadge color="info-gradient" className="ms-2">
            {notificationsCount}
          </CBadge>
        </CDropdownItem>
        {
          //settings
        }
        <CDropdownHeader className="bg-secondary dark:bg-white dark:bg-opacity-10 fw-semibold py-2">
          Settings
        </CDropdownHeader>
        {
          //TODO: definir las rutar para editar el perfil y settings
        }
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownItem href="#">
          <CIcon icon={cilSettings} className="me-2" />
          Settings
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem onClick={onLogOut} href="#">
          <CIcon icon={cilLockLocked} className="me-2" />
          Log Out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

AppHeaderDropdown.propTypes = {
  notificationsCount: PropTypes.number,
  onLogOut: PropTypes.func,
}
export default AppHeaderDropdown
